<script lang="ts">
	import Link from "../Link.svelte";
	import cake from "../../../../core/assets/icons/cake.svg?raw";
	import "@glidejs/glide/dist/css/glide.core.min.css";
	import cakeOneImgSm from "../../assets/images/wedding/banner/cake-one.png?w=400&webp&imagetools";
	import cakeOneImgMd from "../../assets/images/wedding/banner/cake-one.png?w=432&webp&imagetools";
	import cakeOneImgLg from "../../assets/images/wedding/banner/cake-one.png?w=496&webp&imagetools";
	import cakeTwoImgSm from "../../assets/images/wedding/banner/cake-two.png?w=400&webp&imagetools";
	import cakeTwoImgMd from "../../assets/images/wedding/banner/cake-two.png?w=432&webp&imagetools";
	import cakeTwoImgLg from "../../assets/images/wedding/banner/cake-two.png?w=496&webp&imagetools";
	import cakeThreeImgSm from "../../assets/images/wedding/banner/cake-three.png?w=400&webp&imagetools";
	import cakeThreeImgMd from "../../assets/images/wedding/banner/cake-three.png?w=432&webp&imagetools";
	import cakeThreeImgLg from "../../assets/images/wedding/banner/cake-three.png?w=496&webp&imagetools";
	import cakeFourImgSm from "../../assets/images/wedding/banner/cake-four.png?w=400&webp&imagetools";
	import cakeFourImgMd from "../../assets/images/wedding/banner/cake-four.png?w=432&webp&imagetools";
	import cakeFourImgLg from "../../assets/images/wedding/banner/cake-four.png?w=496&webp&imagetools";
	import cakeFiveImgSm from "../../assets/images/wedding/banner/cake-five.png?w=400&webp&imagetools";
	import cakeFiveImgMd from "../../assets/images/wedding/banner/cake-five.png?w=432&webp&imagetools";
	import cakeFiveImgLg from "../../assets/images/wedding/banner/cake-five.png?w=496&webp&imagetools";
	import { weddingsCakeEnquiryPathMap, weddingsPathMap } from "../../../../core/schema/paths/weddingsPathMap.js";
	import { getGlobalSettings } from "../../getGlobalSettings.js";
	import { nbspify } from "../../../../core/utils/nbspify";
	import Glider from "../Glider.svelte";
	import type { ImageSizes } from "../../../../core/schema/Image";

	export let buttonText = "Chci sladkou svatbu";
	export let border = false;

	const { TENANT_LOCALE } = getGlobalSettings();

	const urlSlug = `/${weddingsPathMap[TENANT_LOCALE]}#${weddingsCakeEnquiryPathMap[TENANT_LOCALE]}`;

	const images: ImageSizes[] = [
		{
			sm: cakeOneImgSm,
			md: cakeOneImgMd,
			lg: cakeOneImgLg,
		},
		{
			sm: cakeTwoImgSm,
			md: cakeTwoImgMd,
			lg: cakeTwoImgLg,
		},
		{
			sm: cakeThreeImgSm,
			md: cakeThreeImgMd,
			lg: cakeThreeImgLg,
		},
		{
			sm: cakeFourImgSm,
			md: cakeFourImgMd,
			lg: cakeFourImgLg,
		},
		{
			sm: cakeFiveImgSm,
			md: cakeFiveImgMd,
			lg: cakeFiveImgLg,
		},
	];
</script>

<div
	class="border-secondary-200 flex flex-col items-center gap-10 overflow-hidden rounded-3xl px-6 py-8 min-[400px]:gap-16 sm:px-10 sm:py-12 md:px-14 md:py-16 lg:flex-row lg:gap-16 lg:p-10 lg:pl-16 lg:pr-10 xl:pl-20 xl:pr-4"
	class:border
>
	<div class="flex flex-col justify-center text-center lg:basis-[calc(50%-1.5rem)] lg:justify-start lg:text-left">
		<h2 class="decorative-headline !mx-0 !mb-6 lg:!text-left">Svatba</h2>
		<p class="h2 mb-8 !text-lg lg:!text-xl xl:!text-2xl">{nbspify("Svatební dorty a minidezerty pro váš velký den")}</p>
		<p class="mb-4">
			{nbspify(
				"Svatební dort od nás může být elegantní či rustikální, bílý nebo modrý, ikonicky hladký a nebo texturovaný s královským zdobením z živých květin. Specializujeme se na krémované dorty, kdy celý dort obmažeme moc dobrým krémem z krémového sýra.",
			)}
		</p>
		<p class="mb-10">{nbspify("A je jen na vás, který zvolíte. Milujeme je všechny.")}</p>
		<Link
			class="sm:!text-md mx-auto !rounded-full !px-8 !py-2 sm:!px-12 sm:!py-4 lg:mx-0 xl:!px-14 xl:!py-[1.125rem] xl:!text-[1.375rem]"
			href={urlSlug}
			asButton
			variant="primary"
			icon={cake}
			iconClass="w-6"
		>
			{buttonText}
		</Link>
	</div>
	<Glider firstImageLoading="eager" {images} />
</div>
