<script lang="ts">
	import * as Glide from "@glidejs/glide";
	import type { ActionReturn } from "svelte/action";
	import { disableGlidePerSlideAmount } from "../utils/disableGlidePerSlideAmount";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import arrowLeft from "../../../core/assets/icons/arrow-left.svg?raw";
	import arrowRight from "../../../core/assets/icons/arrow-right.svg?raw";
	import type { ImageLoading, ImageSizes } from "../../../core/schema/Image";
	import GliderArrow from "./GliderArrow.svelte";
	import GliderBullets from "./GliderBullets.svelte";

	export let images: ImageSizes[];
	export let aspectSquare = false;
	export let firstImageLoading: ImageLoading = "lazy";
	export let size: "basic" | "large" = "basic";

	function glide(element: HTMLDivElement): ActionReturn {
		const glideOptions = {
			type: "carousel",
			startAt: 0,
			focusAt: "center",
			perView: 1,
			autoplay: 7000,
			hoverpause: false,
			animationDuration: 650,
			animationTimingFunc: "ease-in-out",
			gap: 9,
			keyboard: true,
		} satisfies Partial<Glide.Options>;

		// eslint-disable-next-line new-cap
		const glide = new Glide.default(element, { ...glideOptions }).mount();
		glide.on("resize", () => {
			disableGlidePerSlideAmount(glide, element);
		});

		return {
			destroy(): void {
				glide.destroy();
			},
		};
	}
</script>

<div
	use:glide
	class="glide relative w-full max-w-[19rem] sm:max-w-[21rem] md:max-w-[20rem] lg:max-w-[27rem] xl:max-w-[31rem] {size ===
		'large' && 'lg:max-w-[31rem] xl:max-w-[37.5rem]'}"
>
	<div class="glide__track overflow-hidden rounded-lg" data-glide-el="track">
		<div
			class="glide__slides {aspectSquare
				? 'h-[18rem] min-[360px]:h-[21rem] md:h-[20rem] lg:h-[27rem] xl:h-[31rem]'
				: 'h-[20rem] min-[360px]:h-[25rem] min-[400px]:h-[30rem] md:h-[35rem] xl:h-[39rem]'}
				{size === 'large' && 'lg:h-[31rem] xl:h-[37.5rem]'}"
		>
			{#each images as image, index}
				<StaticPicture
					class="glide__slide relative h-full w-full overflow-hidden rounded-lg"
					imgClass="!w-full !h-full rounded-lg object-cover {aspectSquare ? 'aspect-square' : ''}"
					image={image.lg}
					width={496}
					height={aspectSquare ? 496 : 621}
					loading={firstImageLoading === "eager" && index === 0 ? "eager" : "lazy"}
					alt="Fotografie"
					sourcesets={{
						"<md": image.sm,
						"<lg": image.md,
						">lg": image.lg,
					}}
				/>
			{/each}
		</div>
	</div>
	<div
		data-glide-el="controls"
		class="my-2 flex w-full items-center justify-center gap-2 sm:gap-4 lg:absolute lg:bottom-6 lg:left-6 lg:right-6 lg:m-0 lg:grid lg:w-[calc(100%-3rem)] lg:grid-cols-[1fr,auto,auto] lg:items-end"
	>
		<GliderArrow arrowDirection="left" icon={arrowLeft} />
		<GliderBullets class="lg:order-first" {images} />
		<GliderArrow arrowDirection="right" icon={arrowRight} />
	</div>
</div>
