<script lang="ts">
	import Icon from "./Icon.svelte";
	export let arrowDirection: "left" | "right";
	export let icon: string;

	let customClass = "";
	export { customClass as class };
</script>

<button
	aria-label={`Posuňte ${arrowDirection === "left" ? "doleva" : "doprava"}`}
	data-glide-dir={arrowDirection === "left" ? "<" : ">"}
	class="border-secondary hover:border-primary group flex h-[3.5rem] w-[3.5rem] shrink-0 items-center justify-center rounded-full border p-4 transition-colors duration-300 min-[500px]:bg-white lg:border-white {customClass}"
>
	<Icon
		{icon}
		class="text-secondary group-hover:text-primary h-6 transition-colors duration-300 {arrowDirection === 'left'
			? 'ml-[.25rem]'
			: 'mr-[.25rem]'}"
	/>
</button>
