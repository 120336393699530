<script lang="ts">
	import type { ImageSizes } from "../../../core/schema/Image";

	export let images: ImageSizes[];

	let customClass = "";
	export { customClass as class };
</script>

<div class="max-w-[19.5rem] grow rounded-full bg-white px-3 py-[.7rem] {customClass}">
	<div class="glide__bullets bg-secondary-200 flex h-0.5" data-glide-el="controls[nav]">
		{#each images as _image, index}
			<span
				class="glide__bullet text-secondary hover:bg-secondary-300 w-full cursor-pointer transition-all duration-200"
				data-glide-dir="={index}"
			></span>
		{/each}
	</div>
</div>
